<template>
  <newspaper-edit></newspaper-edit>
</template>

<script>
import NewspaperEdit from '@/components/newspaper/NewspaperEdit.vue'

export default {
  name: 'NewspaperEditView',
  components: {
    NewspaperEdit
  }
}
</script>
